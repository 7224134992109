<template>
  <div>
    <div class="accordion" role="tablist" v-for="(item, index) in resultados" v-bind:key="index">
      <b-card no-body class="mb-1" style="background: #141414">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle="`accordion-${idPadre}-inner${index}`" variant="info"
            style="background-color: #aa2131">{{ carreras[index].title }}</b-button>
          <b-progress :max="100">
            <b-progress-bar :value="((item.resultado / 300) * 100).toFixed()"
              :label="`${((item.resultado / 300) * 100).toFixed()}%`"></b-progress-bar>
          </b-progress>
        </b-card-header>
        <b-collapse :id="`accordion-${idPadre}-inner${index}`" visible accordion="my-accordion2" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <div v-for="(data, index) in carreras[index].subtitle" v-bind:key="index">
                <h5>{{ data.text }}</h5>
                <ul>
                  <li v-for="(elemento, index) in data.lista" v-bind:key="index">
                    {{ elemento }}
                  </li>
                </ul>
              </div>

              <h5>{{ carreras[index].links.text }}</h5>
              <ul>
                <li v-for="link in carreras[index].links.lista" v-bind:key="link.name">
                  <a :href="link.url" target="_blank" rel="noopener noreferrer">
                    {{ link.name }}</a>
                </li>
              </ul>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>
<script>
import { RESULTADOS_PAGE } from "./const"

export default {
  name: "Resultado",
  components: {},
  data() {
    return {
      carreras: RESULTADOS_PAGE
    }
  },
  props: {
    resultados: Array,
    idPadre: Number
  },
  mounted() {
    try {
      const resultadoOrd = this.resultados
      const posiciones = RESULTADOS_PAGE
      resultadoOrd.sort((a, b) => b.resultado - a.resultado)
      posiciones.sort(
        (a, b) => resultadoOrd[b].resultado - resultadoOrd[a].resultado
      )
    } catch (error) {
      // console.log('e')
    }
  }
}
</script>
