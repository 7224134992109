export const QUESTIONS = [
  {
    id: "AR1",
    title: "Hacer reparaciones, utilizando herramientas o utensilios",
    answer: null
  },
  {
    id: "AR2",
    title: "Construir objetos o hacer trabajos manuales",
    answer: null
  },
  { id: "AR3", title: "Manejar maquinarias y equipos", answer: null },
  {
    id: "AR4",
    title: "Aprender sobre mecánica, carpintería, electricidad o afines",
    answer: null
  },
  {
    id: "AR5",
    title: "Trabajar al aire libre o en espacios abiertos",
    answer: null
  },
  {
    id: "AI1",
    title: "Realizar investigaciones o experimentos científicos",
    answer: null
  },
  {
    id: "AI2",
    title: "Indagar o buscar información sobre temas científicos",
    answer: null
  },
  {
    id: "AI3",
    title: "Aprender  de física, química, matemáticas o biología",
    answer: null
  },
  {
    id: "AI4",
    title: "Resolver problemas utilizando el método científico",
    answer: null
  },
  {
    id: "AI5",
    title: "Analizar las causas y consecuencias de una situación",
    answer: null
  },
  { id: "AA1", title: "Diseñar, dibujar o pintar", answer: null },
  {
    id: "AA2",
    title: "Cantar, tocar un instrumento o realizar actividades musicales",
    answer: null
  },
  {
    id: "AA3",
    title: "Escribir  poesía, novelas u otro tipo de género literario",
    answer: null
  },
  {
    id: "AA4",
    title: "Realizar creaciones artísticas de diferente tipo",
    answer: null
  },
  {
    id: "AA5",
    title: "Aprender sobre arte en alguna de sus manifestaciones",
    answer: null
  },
  {
    id: "AS1",
    title: "Enseñar o ayudar a otras personas a aprender",
    answer: null
  },
  {
    id: "AS2",
    title:
      "Buscar información sobre temas relacionados con psicología, filosofía y humanidades",
    answer: null
  },
  {
    id: "AS3",
    title: "Participar en campañas o fundaciones de ayuda a otros",
    answer: null
  },
  {
    id: "AS4",
    title: "Escuchar a las personas y ayudarlas con sus problemas",
    answer: null
  },
  {
    id: "AS5",
    title: "Trabajar en equipo para lograr un objetivo común",
    answer: null
  },
  {
    id: "AE1",
    title: "Liderar actividades que permitan influir o persuadir a otros",
    answer: null
  },
  {
    id: "AE2",
    title: "Involucrarse en tareas de ventas o mercadeo",
    answer: null
  },
  {
    id: "AE3",
    title: "Aprender  sobre negocios, administración o liderazgo",
    answer: null
  },
  {
    id: "AE4",
    title: "Trabajar en actividades de emprendimiento y autogestión",
    answer: null
  },
  {
    id: "AE5",
    title: "Realizar una presentación ante un auditorio",
    answer: null
  },
  {
    id: "AC1",
    title:
      "Ejecutar procedimientos orientados al funcionamiento  adecuado de un negocio",
    answer: null
  },
  {
    id: "AC2",
    title: "Preparar la documentación de un proceso administrativo",
    answer: null
  },
  {
    id: "AC3",
    title: "Utilizar procesadores de textos (computador, tabletas, etc)",
    answer: null
  },
  {
    id: "AC4",
    title:
      "Aprender sobre secretariado, mantenimiento de registros, contabilidad y afines.",
    answer: null
  },
  {
    id: "AC5",
    title: "Realizar inventarios o controlar los gastos de una empresa",
    answer: null
  }
]

export const QUESTIONS_2 = [
  {
    id: "HR1",
    title: "MECÁNICO - MANUALES",
    descripcion:
      "Capacidad para realizar tareas de tipo mecánico o manual, que exigen coordinación física y uso del cuerpo como herramienta de trabajo, resolviendo problemas de forma práctica. Ejemplos:",
    answer: 0,
    lista: [
      "Operar o reparar máquinas u objetos.",
      "Realizar labores de plomería, electricidad, electrónica, carpintería, mecánica o afines",
      "Hacer trabajo agrícola",
      "Realizar actividades deportivas y/o de ejercicio físico"
    ]
  },
  {
    id: "HR2",
    title: "CIENTÍFICO - INVESTIGATIVAS",
    descripcion:
      " Capacidad para analizar información para seguir una secuencia y lógica, razonar con datos abstractos y utilizar conceptos científicos, resolviendo los problemas de forma teórica. Ejemplos:",
    answer: 0,
    lista: [
      " Elaborar un ensayo o informe académico y sustentar los argumentos",
      "Desarrollar una búsqueda bibliográfica de un tema científico",
      "Resolver problemas que involucren matemáticas, física, química o biología"
    ]
  },
  {
    id: "HR3",
    title: "ARTÍSTICO - CREATIVAS",
    descripcion:
      "Capacidad para crear, diseñar, componer o ejecutar actividades relacionadas con el arte en sus diferentes manifestaciones, pudiendo expresar en el mundo concreto las ideas que surgen en su mente. Ejemplos:",
    answer: 0,
    lista: [
      "Actividades musicales (canto, tocar instrumentos, componer)",
      "Diseñar piezas visuales, gráficos y/o animaciones",
      "Escribir historias, cuentos, poemas u otro tipo de género literario",
      "Dibujar, pintar y/o hacer esculturas",
      "Dar apreciaciones sobre arte y cultura"
    ]
  },
  {
    id: "HR4",
    title: "SOCIALES - HUMANITARIAS",
    descripcion:
      "Capacidad para servir a otros a través de la enseñanza, la orientación, el cuidado, el trabajo en equipo y otras actividades de tipo humanitario, en las que se requiere buscar soluciones a los problemas de las personas. Ejemplos:",
    answer: 0,
    lista: [
      "Conversar o interactuar con personas de todas las edades",
      "Lograr la cooperación y la integración de los miembros de un grupo",
      "Explicar o clarificar un tema",
      "Escuchar activamente y/o aconsejar"
    ]
  },
  {
    id: "HR5",
    title: "NEGOCIOS - EMPRENDIMIENTO",
    descripcion:
      " Capacidad para persuadir, liderar o ejercer influencia, a través de actividades de promoción de servicios y productos, en las que se requiere dar respuesta a necesidades y expectativas de otros. Ejemplos:",
    answer: 0,
    lista: [
      "Convencer a otros de una idea",
      "Hablar en público y sustentar un punto de vista",
      "Vender o hacer transacciones comerciales",
      "Armar o formar parte de grupos con facilidad",
      "Organizar las actividades y animar a otros a lograr las metas del grupo"
    ]
  },
  {
    id: "HR6",
    title: "ADMINISTRATIVAS",
    descripcion:
      "Capacidad para brindar apoyo y responder a los requerimientos de una organización, mediante la ejecución de procedimientos administrativo, que permitan el funcionamiento adecuado de la misma y la atención a las necesidades de sus miembros. Ejemplos:",
    answer: 0,
    lista: [
      "Utilizar programas de computadores (Word, Power point, Excel)",
      "Archivar, mantener records de actividades o registros de información",
      "Manejar computadores, calculadoras, fotocopiadoras y otras máquinas de oficina",
      "Organizar procedimientos y actividades para cumplir metas",
      "Realizar trabajos de oficina de forma ágil"
    ]
  }
]

export const QUESTIONS_3 = [
  {
    title: "OCUPACIONES MECANICO - MANUALES",
    preguntas: [
      { id: "OR1", title: "Mecánica", answer: null },
      { id: "OR2", title: "Electricidad", answer: null },
      { id: "OR3", title: "Agronomía y/o Veterinaria", answer: null },
      { id: "OR4", title: "Arquitectura", answer: null },
      { id: "OR5", title: "Electrónica", answer: null },
      { id: "OR6", title: "Ingeniería", answer: null },
      { id: "OR7", title: "Carrera militar, naval o de policía", answer: null },
      { id: "OR8", title: "Aeronáutica", answer: null },
      { id: "OR9", title: "Deportes y actividad física", answer: null }
    ]
  },
  {
    title: "OCUPACIONES CIENTIFICO - ANALITICAS",
    preguntas: [
      { id: "OI1", title: "Química y/biología", answer: null },
      { id: "OI2", title: "Ciencias Sociales", answer: null },
      { id: "OI3", title: "Medicina", answer: null },
      { id: "OI4", title: "Geología", answer: null },
      { id: "OI5", title: "Odontología", answer: null },
      { id: "OI6", title: "Matemáticas", answer: null },
      { id: "OI7", title: "Física", answer: null },
      { id: "OI8", title: "Economía", answer: null },
      { id: "OI9", title: "Computación", answer: null }
    ]
  },
  {
    title: "OCUPACIONES ARTISTICO - CREATIVAS",
    preguntas: [
      {
        id: "OA1",
        title: "Producción de textos escritos (poemas, libros, guiones)",
        answer: null
      },
      { id: "OA2", title: "Música", answer: null },
      { id: "OA3", title: "Arte dramático", answer: null },
      { id: "OA4", title: "Diseño publicitario", answer: null },
      { id: "OA5", title: "Comunicación y/o Periodismo", answer: null },
      { id: "OA6", title: "Diseño de piezas industriales", answer: null },
      { id: "OA7", title: "Escultura", answer: null },
      { id: "OA8", title: "Ambientación de espacios", answer: null },
      { id: "OA9", title: "Cocina o gastronomía", answer: null }
    ]
  },
  {
    title: "OCUPACIONES- SOCIALES - HUMANITARIAS",
    preguntas: [
      { id: "OS1", title: "Educación infantil", answer: null },
      { id: "OS2", title: "Psicología", answer: null },
      { id: "OS3", title: "Filosofía", answer: null },
      { id: "OS4", title: "Lúdica y recreación", answer: null },
      { id: "OS5", title: "Educación Media y/o superior", answer: null },
      { id: "OS6", title: "Fonoaudiología", answer: null },
      { id: "OS7", title: "Educación Especial", answer: null },
      { id: "OS8", title: "Enfermería", answer: null },
      { id: "OS9", title: "Leyes y/o política", answer: null }
    ]
  },
  {
    title: "OCUPACIONES NEGOCIOS - EMPRENDIMIENTO",
    preguntas: [
      { id: "OE1", title: "Negocios", answer: null },
      { id: "OE2", title: "Inversiones", answer: null },
      { id: "OE3", title: "Administración de empresas", answer: null },
      { id: "OE4", title: "Relaciones públicas", answer: null },
      { id: "OE5", title: "Bienes raíces", answer: null },
      { id: "OE6", title: "Mercadeo y/o ventas", answer: null },
      { id: "OE7", title: "Gestión de recursos humanos", answer: null },
      { id: "OE8", title: "Turismo y/o viajes", answer: null },
      { id: "OE9", title: "Manejo de la banca", answer: null }
    ]
  },
  {
    title: "OCUPACIONES ADMINISTRATIVAS",
    preguntas: [
      { id: "OC1", title: "Contabilidad", answer: null },
      { id: "OC2", title: "Análisis financiero", answer: null },
      { id: "OC3", title: "Manejo de presupuestos", answer: null },
      { id: "OC4", title: "Control de inventarios", answer: null },
      { id: "OC5", title: "Mantenimiento de oficinas", answer: null },
      { id: "OC6", title: "Cobranza de cartera", answer: null },
      {
        id: "OC7",
        title: "Secretariado o asistencia administrativa",
        answer: null
      },
      { id: "OC8", title: "Manejo de bases de datos", answer: null },
      {
        id: "OC9",
        title: "Logística de servicios (alimentación, aseo, transporte)",
        answer: null
      }
    ]
  }
]

export const RESULTADOS_PAGE = [
  {
    id: "r",
    title: "MECANICO - MANUALES",
    resultado: 0,
    subtitle: [
      {
        text: "Caracteristicas de las personas con este perfil",
        lista: [
          " Presentan interés por el desarrollo de las actividades que requieran el uso de energía física, el manejo de herramientas y la manipulación de objetos. Se orientan a conseguir resultados visibles y que aporten un producto, siendo prácticos y persistentes."
        ]
      },
      {
        text: "Carreras relacionadas",
        lista: [
          "Ingeniería",
          "Arquitectura",
          "Agronomía",
          "Trabajos Militares",
          "Veterinaria",
          "Deportista de alto rendimiento"
        ]
      }
    ],
    links: {
      text: "Click en alguna de las opciones disponibles en Uninorte",
      lista: [
        {
          name: "Arquitectura",
          url: "https://www.uninorte.edu.co/web/arquitectura"
        },
        {
          name: "Ingeniería Civil",
          url: "https://www.uninorte.edu.co/web/ingenieria-civil"
        },
        {
          name: "Ingeniería de Sistemas y Computación",
          url: "https://www.uninorte.edu.co/web/ingenieria-de-sistemas-y-computacion"
        },
        {
          name: "Ingeniería Eléctrica",
          url: "https://www.uninorte.edu.co/web/ingenieria-electronica"
        },
        {
          name: "Ingeniería Industrial",
          url: "https://www.uninorte.edu.co/web/ingenieria-industrial"
        },
        {
          name: "Ingeniería Mecánica",
          url: "https://www.uninorte.edu.co/web/ingenieria-mecanica"
        }
      ]
    }
  },
  {
    id: "i",
    title: "CIENTIFICO - INVESTIGATIVAS",
    resultado: 0,
    subtitle: [
      {
        text: "Caracteristicas de las personas con este perfil",
        lista: [
          `Se interesan por actividades que requieran la solución
       de problemas, haciendo uso del método científico, para
       lo cual suelen implementar procedimientos que les
       permitan buscar soluciones funcionales. Son curiosos,
       analíticos y reflexivos.`
        ]
      },
      {
        text: "Carreras del campo de la salud como:",
        lista: [
          "Medicina",
          "Química",
          "Biología",
          "Bacteriología",
          "Odontología",
          "Fisioterapia"
        ]
      },
      {
        text: "Carreras relacionadas con las ciencias básicas:",
        lista: ["Ciencias Básicas", "Geología", "Matemáticas", "Física"]
      }
    ],
    links: {
      text: "Click en alguna de las opciones disponibles en Uninorte",
      lista: [
        {
          name: "Matemáticas",
          url: "https://www.uninorte.edu.co/web/matematicas"
        },
        {
          name: "Medicina",
          url: "https://www.uninorte.edu.co/web/medicina"
        },
        {
          name: "Odontología",
          url: "https://www.uninorte.edu.co/web/odontologia"
        },
        {
          name: "Geología",
          url: "https://www.uninorte.edu.co/web/geologia/geologia"
        }
      ]
    }
  },
  {
    id: "a",
    title: "ARTISTICO - CREATIVAS",
    resultado: 0,
    subtitle: [
      {
        text: "Caracteristicas de las personas con este perfil",
        lista: [
          ` Se interesan por actividades en las que puedan diseñar,
         arman y ensamblar piezas haciendo uso de sus ideas
         creativas. Tienen talento en diferentes tipos de artes
         como escritura, pintura, canto, dibujo, entre otras.`
        ]
      },
      {
        text: "Carreras del campo Artístico son:",
        lista: ["Música", "Escultura", "Canto", "Actuación"]
      },
      {
        text: "Carreras del campo de la publicidad y el diseño son:",
        lista: [
          "Diseño Industrial",
          "Diseño Gráfico",
          "Diseño de Interiores",
          "Diseño de Modas"
        ]
      }
    ],
    links: {
      text: "Click en alguna de las opciones disponibles en Uninorte",
      lista: [
        {
          name: "Diseño Grafico",
          url: "https://www.uninorte.edu.co/web/diseno-grafico"
        },
        {
          name: "Diseño Industrial",
          url: "https://www.uninorte.edu.co/web/diseno-industrial"
        },
        {
          name: "Comunicación Social y Periodismo",
          url: "https://www.uninorte.edu.co/web/comunicacion-social-y-periodismo"
        },
        {
          name: "Música",
          url: "https://www.uninorte.edu.co/web/musica"
        }
      ]
    }
  },
  {
    id: "s",
    title: "SOCIALES - HUMANITARIAS",
    resultado: 0,
    subtitle: [
      {
        text: "Caracteristicas de las personas con este perfil",
        lista: [
          `Muestran interés por actividades que impliquen relaciones
        interpersonales, liderazgo y el servicio a los demás,
        persuasión y trabajos en equipo. Tienen habilidades para
        relacionarse con otras personas y son empáticas.`
        ]
      },
      {
        text: "Carreras relacionadas con cada perfil",
        lista: [
          "Educación en sus diferentes niveles",
          "Psicología",
          "Sociología",
          "Recreación y Turismo",
          "Trabajo Social",
          "Fonoaudiología",
          "Terapia del Lenguaje",
          "Vocación Religiosa",
          "Derecho",
          "Ciencias Políticas"
        ]
      }
    ],
    links: {
      text: "Click en alguna de las opciones disponibles en Uninorte",
      lista: [
        {
          name: "Enfermeria",
          url: "https://www.uninorte.edu.co/web/enfermeria"
        },
        {
          name: "Ciencia Política y Gobierno",
          url: "https://www.uninorte.edu.co/web/ciencia-politica-y-gobierno/ciencia-politica-y-gobierno"
        },
        {
          name: "Derecho",
          url: "https://www.uninorte.edu.co/web/derecho"
        },
        {
          name: "Pedagogía",
          url: "https://www.uninorte.edu.co/web/licenciatura-en-pedagogia-infantil"
        },
        {
          name: "Economía",
          url: "https://www.uninorte.edu.co/web/economia"
        },
        {
          name: "Filosofía y Humanidades",
          url: "https://www.uninorte.edu.co/web/filosofiayhumanidades"
        },
        {
          name: "Psicología",
          url: "https://www.uninorte.edu.co/web/psicologia"
        },
        {
          name: " Licenciatura en Filosofía y Humanidades",
          url: "https://www.uninorte.edu.co/web/licenciatura-en-filosofia-y-humanidades"
        },
        {
          name: "Licenciatura en Matemáticas",
          url: "https://www.uninorte.edu.co/web/licenciatura-en-matematicas"
        }
      ]
    }
  },
  {
    id: "e",
    title: "NEGOCIOS - EMPRENDIMIENTO",
    resultado: 0,
    subtitle: [
      {
        text: "Caracteristicas de las personas con este perfil",
        lista: [
          `Les gusta comunicarse y ejercer una influencia sobre los demás
         de tal forma que se inclinan por actividades que requieran la
         ejecución de proyectos y la venta de productos se expresan
         fácilmente y se orientan hacia la consecución de logros.`
        ]
      },
      {
        text: "Carreras relacionadas con cada perfil",
        lista: [
          "Administración de Empresas",
          "Relaciones Internacionales",
          "Mercadeo y Ventas",
          "Gestión de Recursos Humanos",
          "Economía y Finanzas"
        ]
      }
    ],
    links: {
      text: "Click en alguna de las opciones disponibles en Uninorte",
      lista: [
        {
          name: "Relaciones Internacionales",
          url: "https://www.uninorte.edu.co/web/relaciones-internacionales"
        },
        {
          name: "Negocios Internacionales",
          url: "https://www.uninorte.edu.co/web/negocios-internacionales"
        },
        {
          name: "Administración de Empresas",
          url: "https://www.uninorte.edu.co/web/web/administracion-de-empresas"
        },
        {
          name: "Economía",
          url: "https://www.uninorte.edu.co/web/economia"
        }
      ]
    }
  },
  {
    id: "c",
    title: "ADMINISTRATIVAS",
    resultado: 0,
    subtitle: [
      {
        text: "Caracteristicas de las personas con este perfil",
        lista: [
          ` Disfruta las actividades que requieran la ejecución de
        procesos y tareas que requieran horarios, manejos de datos,
        clasificación de información y otras tareas de tipo
        administrativo. Son estables, estructurados y pueden trabajar
        bajo instrucciones.`
        ]
      },
      {
        text: "Las carreras de tipo operativo que permitan ejercer labores como:",
        lista: ["Cajero", "Recepcionista", "Contador"]
      },
      {
        text: " Manejo de cadena de suministros de alimentación y mantenimiento de áreas como:",
        lista: ["Casas", "Restaurantes", "Jardines"]
      }
    ],
    links: {
      text: "Click en alguna de las opciones disponibles en Uninorte",
      lista: [
        {
          name: "Contaduría Pública",
          url: "https://www.uninorte.edu.co/web/contaduria-publica"
        }
      ]
    }
  }
]
