<template>
  <b-container>
    <b-row class="text-center"><b-col>
        <h3 id="resultado-test" class="iq-title text-capitalize">
          Resultados del Test Vocacional
        </h3>
      </b-col></b-row>
    <b-row v-for="(list, index) of percentage" :key="index">
      <b-col class="accordion" role="tablist">
        <b-card no-body class="mb-1" style="background: #141414">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="'accordion-' + index" variant="info" style="background-color: #aa2131">{{
              list[0].id.slice(1) }}</b-button>
          </b-card-header>
          <b-collapse :id="'accordion-' + index" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <Resultado :resultados="list" :idPadre="index" />
            </b-card-body>
          </b-collapse>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "../../../config/pluginInit"
import Resultado from "./Resultado.vue"
import firebase from "firebase/app"
import "firebase/firestore"

const db = firebase.firestore()
export default {
  name: "ResultadosTest",
  components: { Resultado },
  data() {
    return {
      userInfo: null,
      percentage: [],
      idUser: ''
    }
  },
  mounted() {
    core.index()
    this.userInfo = this.$store.getters["Setting/authUserState"]
    this.idUser = this.userInfo.user.idNumber
    db.collection("testVocacional").doc(this.idUser).collection(this.idUser)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const resultados = [
            {
              id: `r${doc.id}`,
              resultado: 3 * parseFloat(doc.data().mecanicoManuales)
            },
            {
              id: `i${doc.id}`,
              resultado: 3 * parseFloat(doc.data().cientificoInvestigativas)
            },
            {
              id: `a${doc.id}`,
              resultado: 3 * parseFloat(doc.data().artisticoCreativas)
            },
            {
              id: `s${doc.id}`,
              resultado: 3 * parseFloat(doc.data().socialesHumanitarias)
            },
            {
              id: `e${doc.id}`,
              resultado: 3 * parseFloat(doc.data().negociosEmprendimiento)
            },
            {
              id: `c${doc.id}`,
              resultado: 3 * parseFloat(doc.data().administrativas)
            }
          ]

          this.percentage.unshift(resultados)
        })
      })
      .catch((error) => {
        console.log("Error getting documents: ", error)
      })
  },
  methods: {}
}
</script>
<style scoped>
main {
  overflow-y: scroll;
}
</style>
